import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import logoImage from '../../../images/logo.png';

function Header() {
  return (
    <div>
      <Navbar expand="lg md sm" className="py-0 w-100">
        <Container className="container mx-1">
          <Navbar.Brand>
            <Link to="/" className="navbar-brand d-flex align-items-center">
              <img src={logoImage} width="70" height="70"></img>
              <span className="mx-3 text-light text-center lh-1 fw-semibold fs-3">
                Acharya Chanakya
                <br></br>
                <span className="fs-3 ">Mahavidyalaya</span>
              </span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="bg-light"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-end w-100">
              <Nav.Link href="/" className="text-uppercase fw-semibold px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  className="bi bi-house-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z" />
                  <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z" />
                </svg>
              </Nav.Link>
              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="About"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/history">History</NavDropdown.Item>
                <NavDropdown.Item href="/mission&vision">
                  Mission & Vision
                </NavDropdown.Item>
                <NavDropdown.Item href="/objective">Objective</NavDropdown.Item>
                <NavDropdown.Item href="/manager-msg">
                  Manager's Message
                </NavDropdown.Item>
                <NavDropdown.Item href="/principal-msg">
                  Principal's Message
                </NavDropdown.Item>
                <NavDropdown.Item href="/kulgeet">
                  College Kulgeet
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Courses"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/BEd">B.Ed.</NavDropdown.Item>
                <NavDropdown.Item href="/DelEd">D.El.Ed.</NavDropdown.Item>
                <NavDropdown.Item href="/undergraduate">
                  Undergraduate
                </NavDropdown.Item>
                <NavDropdown.Item href="postgraduate">
                  Postgraduate
                </NavDropdown.Item>
              </NavDropdown>

              {/* student  */}
              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Student"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/studentData">Student Data</NavDropdown.Item>
                <NavDropdown.Item href="/biometricAttendenceDeled">Biometric Attendance
                </NavDropdown.Item>
                <NavDropdown.Item href="/complaints">Online Grievances</NavDropdown.Item>
                <NavDropdown.Item href="/feedback"> Feedback</NavDropdown.Item>
                <NavDropdown.Item href="/samarthPortal"> Samarth Portal Verification</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Admission"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/admission-procedure">
                  Admission Procedure
                </NavDropdown.Item>
                <NavDropdown.Item href="/college-rules-and-regulations">
                  Rules & Regulations of College
                </NavDropdown.Item>
                <NavDropdown.Item href="/library-rules-and-regulations">
                  {" "}
                  Rules & Regulations of Library
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Facilities"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/smartClass">
                  Smart Classroom
                </NavDropdown.Item>
                <NavDropdown.Item href="/lab">
                  {" "}
                  Lab & Laboratory{" "}
                </NavDropdown.Item>
                <NavDropdown.Item href="/library"> Library </NavDropdown.Item>
                <NavDropdown.Item href="/sports"> Sports </NavDropdown.Item>
                <NavDropdown.Item href="/nss"> NSS </NavDropdown.Item>
                <NavDropdown.Item href="/computer-center">
                  {" "}
                  Computer Center{" "}
                </NavDropdown.Item>
                <NavDropdown.Item href="/college-campus">
                  {" "}
                  College Campus{" "}
                </NavDropdown.Item>
                <NavDropdown.Item href="/swyamPrabha">
                  {" "}
                  Swyam Prabha{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Download"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="#">Syllabus</NavDropdown.Item>
                <NavDropdown.Item href="#"> Time Table </NavDropdown.Item>
                <NavDropdown.Item href="./img/academic-calender.pdf">
                  Academic Calender
                </NavDropdown.Item>
                <NavDropdown.Item href="./img/holidaylist.pdf">
                  Holiday List
                </NavDropdown.Item>
                <NavDropdown.Item href="./img/all_exam_notice.pdf">
                  Exam Notice
                </NavDropdown.Item>
                <NavDropdown.Item href="/balancesheet2022">
                  Balance Sheet 2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/balancsheet2023">
                  Balance Sheet 2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/incomeexpenditure2022">
                  Income Expenditure 2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/incomeexpenditure2023">
                  Income Expenditure 2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/receiptpayment2022">
                  Receipt Payment 2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/receiptpayment2023">
                  Receipt Payment 2023
                </NavDropdown.Item>
              </NavDropdown>




              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Disciplinary"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="#">Anti-Ragging Cell</NavDropdown.Item>
                <NavDropdown.Item href="#">
                  {" "}
                  Student Grievance Cell
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  {" "}
                  Gender Harassment Cell
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  Equal Opportunity Cell
                </NavDropdown.Item>
              </NavDropdown>

              {/* <Nav.Link href="/faculty" className="text-uppercase fw-semibold px-1">
                Faculty
              </Nav.Link> */}
              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Faculty"
                id="basic-nav-dropdown"
              >

                <NavDropdown.Item href="/bed">
                  B.ED.
                </NavDropdown.Item>
                <NavDropdown.Item href="/deled">
                  D.EL.ED.
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className="text-uppercase fw-semibold px-0"
                title="Result"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/talent-search-result-2024">
                  Talent Search Exam <br />
                  2024 Result
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link href='/courses' className='text-uppercase fw-semibold px-1'>Report</Nav.Link> */}
              <Nav.Link
                href="/gallery"
                className="text-uppercase fw-semibold px-1"
              >
                Gallery
              </Nav.Link>
              <Nav.Link
                href="/contact"
                className="text-uppercase fw-semibold px-1"
              >
                CONTACT
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;