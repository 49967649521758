import React from 'react';
import { Card } from 'react-bootstrap';

function ChooseSection() {
  return (
    <div>
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-8">
            <Card className="d-flex align-items-center border-0 h-100">
              <Card.Body>
                <Card.Title className="text-center text-capitalize mb-3">
                  <h2
                    className="text-center mb-5 px-2 mx-0 "
                    style={{
                      borderBottom: "solid black 2px ",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Acharya Chanakya Mahavidyalaya{" "}
                  </h2>
                </Card.Title>
                <Card.Text className="text-center">
                  <p className="px-2 mx-0" style={{ textAlign: "justify" }}>
                    Acharya Chanakya Mahavidyalaya, is named on the great
                    Maharishi ACHARYA CHANKYA. Acharya chankya mahaviadyalya
                    Samiti ,semari sultanpur was established by Dr.Om Prakash
                    Tripathi the manager of the acharya chankya samiti in the
                    year of 2007. The college got affiliated to Dr.Ram Manohar
                    Lohia Avadh University , Faizabad in the Graduate and Post
                    Graduate Courses. Also the college has got affiliation in
                    two years B.Ed course. Some vocational courses also run like
                    D.El.ED (BTC) from Parkisha Niyamak Allhabad Uttar Pradesh.
                    And Diploma In Homoeopathic Pharmacy (DHP) affiliated by
                    Homoeopathic Medicine Board Lucknow, Uttar Pradesh.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card
              className="d-flex align-items-center  h-100"
              style={{
                backgroundColor: "grey",
                color: "white",
                border: "solid 14px #2f2f30",
                fontFamily: "Caveat Brush, cursive",
              }}
            >
              <div className="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="white"
                  class="bi bi-clipboard-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                  />
                </svg>
              </div>
              <Card.Body>
                <Card.Title
                  className="text-center text-capitalize mb-3"
                  style={{
                    fontFamily: "Caveat Brush, cursive",
                    fontSize: "25px",
                  }}
                >
                  <u>Notice Board</u>
                </Card.Title>
                <Card.Text className="text-center">
                  <p
                    className="px-2 mx-0"
                    style={{
                      textAlign: "justify",
                      fontFamily: " cursive",
                      fontSize: "14px",
                    }}
                  >
                    Talent Search Examination 2024 prize distribution ceremony
                    scheduled to be held on 14-02-2024 has been postponed due to
                    University Examination, it will be happening soon.
                    <br />
                    Kindly visit our website regularly for current updates.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseSection;