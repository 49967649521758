import React, { useState } from "react";
import "./Faculty.css";
import { Card } from 'react-bootstrap';
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import certificateImage from '../../images/certificate.jpg';


function Bed() {

    const [result, setResult] = useState(null);

    const searchResult = async () => {
        var rollno = document.getElementById("rollno").value;
        // console.log(rollno);
        var dt = {
            "rollno": rollno
        }
        await fetch("https://app.acmcollege.org/api/getResultByRollNo", {
            method: "POST",
            body: JSON.stringify(dt),
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(res => {
                setResult(res.data);
            })
    }


    const pdfPath = 'https://app.acmcollege.org/uploads/docs/faculty_bed.pdf';

    return (
        <div className="talent-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">B.ED. Faculty</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5" style={{ textAlign: "center" }}>

                        </h2>

                    </div>

                    <div>

                        <iframe
                            src={pdfPath}
                            type="application/pdf"
                            width="100%"
                            height="500"
                        >

                        </iframe>
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Bed;
